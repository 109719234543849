import React, { useState } from 'react'
import superagent from 'superagent'
import { useAlert, useAuth } from '../App'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import useStyles from '../styles/useStyles'
import Box from '@mui/material/Box'

import SendIcon from '@mui/icons-material/Send'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Stack
} from '@mui/material'
import TextField from '@mui/material/TextField'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import CircleIcon from '@mui/icons-material/Circle'

const Form = () => {
  const classes = useStyles()
  const [voxuid, setVoxuid] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [mail, setMail] = useState('')
  const [user, setUser] = useState(null)
  const { setAlert } = useAlert()
  const [modalType, setModalType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [newMail, setNewMail] = useState('')
  const [newPhone, setNewPhone] = useState('')

  const auth = useAuth()
  const modalOpen = (type: string) => {
    setModalType(type)
  }

  const modalClose = () => {
    setSelected(null)
    setNewMail('')
    setNewPhone('')
    setModalType(null)
  }
  const setPhoneEdited = (phone: any) => {
    setSelected(phone)
    setModalType('editPhone')
  }
  const setMailEdited = (mail: any) => {
    setSelected(mail)
    setModalType('editMail')
  }

  const renderModal = () => {
    if (modalType === 'delete') {
      return (
        <React.Fragment>
          <DialogTitle>Delete Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will permanently delete user's account
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={modalClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={sendDelete}>
              Delete
            </Button>
          </DialogActions>
        </React.Fragment>
      )
    } else if (modalType === 'editPhone') {
      return (
        <React.Fragment>
          <DialogTitle>Edit phone number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will permanently delete selected phone number and replace it
              with the new one. User need log out and log in with new phone
              number.
            </DialogContentText>
            <TextField
              fullWidth
              autoComplete="off"
              name="newPhoneNumber"
              label="New phone number"
              className={classes.textField}
              InputProps={{
                className: classes.inputField,
                disableUnderline: true,

                startAdornment: (
                  <InputAdornment position="start">
                    <LockOpenIcon />
                  </InputAdornment>
                )
              }}
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              placeholder="+33612345678"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={modalClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={sendPhone}>
              Send
            </Button>
          </DialogActions>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <DialogTitle>Edit mail</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will permanently delete selected mail and replace it with the
              new one.
            </DialogContentText>
            <TextField
              fullWidth
              autoComplete="off"
              name="newMail"
              label="New mail"
              className={classes.textField}
              InputProps={{
                className: classes.inputField,
                disableUnderline: true,

                startAdornment: (
                  <InputAdornment position="start">
                    <LockOpenIcon />
                  </InputAdornment>
                )
              }}
              value={newMail}
              onChange={(e) => setNewMail(e.target.value)}
              placeholder="voxist@voxist.com"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={modalClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={sendMail}>
              Send
            </Button>
          </DialogActions>
        </React.Fragment>
      )
    }
  }

  const sendMail = async () => {
    setLoading(true)
    try {
      const token = await auth.getToken()
      const response = await superagent
        .post(
          process.env.REACT_APP_DOCKER ? '/email' : 'http://localhost:80/email'
        )
        .auth(token, { type: 'bearer' })
        .send({ voxuid: user?.voxuid, suid: selected, newMail })
      setUser(response?.body)
      setVoxuid('')
      setPhoneNumber('')
      setMail('')
      modalClose()
      setLoading(false)
    } catch (error) {
      setUser(null)
      setAlert({ type: 'error', text: error?.message })
      setLoading(false)
    }
  }

  const sendPhone = async () => {
    setLoading(true)
    try {
      const token = await auth.getToken()
      const response = await superagent
        .post(
          process.env.REACT_APP_DOCKER ? '/phone' : 'http://localhost:80/phone'
        )
        .auth(token, { type: 'bearer' })
        .send({ voxuid: user?.voxuid, suid: selected, newPhone })
      setUser(response?.body)
      setVoxuid('')
      setPhoneNumber('')
      setMail('')
      modalClose()
      setLoading(false)
    } catch (error) {
      setUser(null)
      setAlert({ type: 'error', text: error?.message })
      setLoading(false)
    }
  }

  const sendDelete = async () => {
    setLoading(true)
    try {
      const token = await auth.getToken()
      await superagent
        .delete(
          process.env.REACT_APP_DOCKER ? '/user' : 'http://localhost:80/user'
        )
        .auth(token, { type: 'bearer' })
        .send({ voxuid: user?.voxuid })
      setUser(null)
      setVoxuid('')
      setPhoneNumber('')
      setMail('')
      modalClose()
      setLoading(false)
    } catch (error) {
      setUser(null)
      setAlert({ type: 'error', text: error?.message })
      setLoading(false)
    }
  }

  const send = async () => {
    setLoading(true)
    setUser(null)
    try {
      const token = await auth.getToken()
      const response = await superagent
        .post(process.env.REACT_APP_DOCKER ? '/' : 'http://localhost:80/')
        .auth(token, { type: 'bearer' })
        .send({ voxuid, phoneNumber, email: mail })
      setUser(response?.body)
      setLoading(false)
      setVoxuid('')
      setPhoneNumber('')
      setMail('')
    } catch (error) {
      setUser(null)
      setAlert({ type: 'error', text: error?.message })
      setLoading(false)
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
            style={{
              maxWidth: '512px',
              backdropFilter: 'blur(4px)'
            }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                className={classes.paddingBox}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box
                        fontWeight="fontWeightBold"
                        fontSize="h6.fontSize"
                        textAlign="center"
                      >
                        {'Welcome to Voxist Admin'}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                className={classes.paddingBox}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="voxuid"
                    label="Voxuid"
                    className={classes.textField}
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,

                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                    value={voxuid}
                    onChange={(e) => setVoxuid(e.target.value)}
                    placeholder="voxuid-xxxxxx-xxxxxx-xxxxx"
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="phoneNumber"
                    label="Phone number"
                    className={classes.textField}
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,

                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="+33612345678"
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="mail"
                    label="Mail"
                    className={classes.textField}
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,

                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="voxist@voxist.com"
                  />
                  <LoadingButton
                    fullWidth
                    className={classes.buttonSquare}
                    loading={loading}
                    // loadingPosition="start"
                    onClick={send}
                    disabled={
                      !voxuid?.length && !phoneNumber?.length && !mail?.length
                    }
                    variant="contained"
                    startIcon={<SendIcon />}
                  >
                    send
                  </LoadingButton>
                </Grid>
              </Grid>
              {user && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" alignContent="center">
                    <Grid
                      container
                      style={{
                        maxWidth: '512px',
                        backdropFilter: 'blur(4px)'
                      }}
                      className={classes.gridContainer}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Language : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.language}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'First name : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.firstName}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Last name : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.lastName}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Voxuid : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.voxuid}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Phone numbers : '}
                        </Box>
                        {(user?.phoneNumbers || []).map((phone) => (
                          <Grid
                            container
                            justifyContent="space-between"
                            alignContent="center"
                            className={classes.paddingBox}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              style={{ width: '100%' }}
                              spacing={2}
                            >
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize="caption.fontSize"
                                textAlign="center"
                              >
                                {phone?.phone}
                              </Box>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize="caption.fontSize"
                                textAlign="center"
                              >
                                {phone?.carrier?.name}
                              </Box>
                              <Stack
                                alignItems="center"
                                justifyContent="flex-end"
                                direction="row"
                                style={{ width: '100%' }}
                                spacing={2}
                              >
                                <CircleIcon
                                  color={phone?.active ? 'success' : 'error'}
                                />

                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setPhoneEdited(phone?.suid)}
                                >
                                  Edit
                                </Button>
                              </Stack>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Mails : '}
                        </Box>
                        {(user?.mails || []).map((mail) => (
                          <Grid
                            container
                            justifyContent="space-between"
                            alignContent="center"
                            className={classes.paddingBox}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              style={{ width: '100%' }}
                            >
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize="caption.fontSize"
                                textAlign="center"
                              >
                                {mail?.mail}
                              </Box>
                              <Stack
                                alignItems="center"
                                justifyContent="flex-end"
                                direction="row"
                                style={{ width: '100%' }}
                                spacing={2}
                              >
                                <CircleIcon
                                  color={mail?.active ? 'success' : 'error'}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setMailEdited(mail?.suid)}
                                >
                                  Edit
                                </Button>
                              </Stack>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Voxuid : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.voxuid}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Plan : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.plan}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Renew date : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {new Date(user?.renew).toUTCString()}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Transcriptions left : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {user?.transcriptions}
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.paddingBox}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body1.fontSize"
                          textAlign="center"
                        >
                          {'Creation date : '}
                        </Box>
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="caption.fontSize"
                          textAlign="center"
                        >
                          {new Date(user?.creationDate).toUTCString()}
                        </Box>
                      </Grid>
                      {user?.lastVoicemail && (
                        <Grid
                          container
                          justifyContent="space-between"
                          alignContent="center"
                          className={classes.paddingBox}
                        >
                          <Box
                            fontWeight="fontWeightBold"
                            fontSize="body1.fontSize"
                            textAlign="center"
                          >
                            {'last voicemail event : '}
                          </Box>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignContent="center"
                            className={classes.paddingBox}
                          >
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="body1.fontSize"
                              textAlign="left"
                            >
                              {'Date : '}
                            </Box>
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="caption.fontSize"
                              textAlign="right"
                            >
                              {new Date(
                                user?.lastVoicemail?.date
                              ).toUTCString()}
                            </Box>
                          </Grid>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignContent="center"
                            className={classes.paddingBox}
                          >
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="body1.fontSize"
                              textAlign="left"
                            >
                              {'Caller : '}
                            </Box>
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="caption.fontSize"
                              textAlign="right"
                            >
                              {user?.lastVoicemail?.callerNumber}
                            </Box>
                          </Grid>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignContent="center"
                            className={classes.paddingBox}
                          >
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="body1.fontSize"
                              textAlign="left"
                            >
                              {'vmuid : '}
                            </Box>
                            <Box
                              fontWeight="fontWeightBold"
                              fontSize="caption.fontSize"
                              textAlign="right"
                            >
                              {user?.lastVoicemail?.vmuid}
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        onClick={() => modalOpen('delete')}
                      >
                        Delete account
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Dialog open={Boolean(modalType)} onClose={modalClose}>
                {renderModal()}
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Form
