import './App.css'
import { useState, useEffect, createContext, useContext, useMemo } from 'react'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'firebase/auth'
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from 'react-router-dom'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Logout from '@mui/icons-material/Logout'
import Tooltip from '@mui/material/Tooltip'

import LogoAsr from './assets/logo_voxist_asr.svg'

import Home from './routes/home'
import SendReset from 'routes/sendReset'
import Form from 'routes/form'
import Reset from 'routes/reset'
import { useScrollTrigger } from '@mui/material'
import CodeHandler from 'routes/codeHandler'

const AuthContext = createContext(null)

const AlertContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const navigate = useNavigate()
  const { setAlert } = useAlert()
  auth.languageCode = 'fr'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userOb) => {
      if (userOb) {
        // console.log(userOb)
        setUser(userOb)
        setAlert({ type: 'success', text: 'logged in' })
      } else {
        setUser(null)
      }
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getToken = async () => {
    try {
      const token = await auth.currentUser.getIdToken()
      return token
    } catch (error) {
      setAlert({ type: 'error', text: 'no user' })
    }
  }
  const sendReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email)
      navigate('/')
    } catch (error) {
      setAlert({ type: 'error', text: 'issue with firebase' })
    }
  }
  const resetPass = async (oob, password) => {
    try {
      await confirmPasswordReset(auth, oob, password)
      navigate('/')
    } catch (error) {
      setAlert({ type: 'error', text: 'issue with firebase' })
    }
  }

  const signIn = async (email, password) => {
    try {
      return await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      setAlert({ type: 'error', text: 'bad login' })
    }
  }

  const signOut = async () => {
    await auth.signOut()
  }

  const value = { user, signIn, signOut, getToken, sendReset, resetPass }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

const CheckAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.user) {
    return <Navigate to="/form" state={{ from: location }} replace />
  }

  return children
}

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null)
  const value = { alert, setAlert }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

const useAlert = () => useContext(AlertContext)

const Layout = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()
  const { alert, setAlert } = useAlert()

  useEffect(() => {
    let timeout = null
    if (alert) {
      timeout = setTimeout(() => setAlert(null), 5000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        style={{
          backgroundColor: trigger
            ? theme.palette.mode === 'dark'
              ? '#000000'
              : '#FFFFFF'
            : 'transparent',
          top: 0,
          position: 'fixed',
          zIndex: 1,
          right: 0,
          left: 0,
          alignItems: 'center',
          justifyContent:
            location?.pathname === '/form' ? 'space-between' : 'center',
          alignContent: 'center'
        }}
      >
        {location?.pathname !== '/' && (
          <Tooltip title="Home">
            <Link to="/" style={{}}>
              <img src={LogoAsr} style={{ width: 54, height: 54 }} alt="icon" />

              {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, width: '100%', textAlign: 'center' }}
            >
              ASR
            </Typography> */}
            </Link>
          </Tooltip>
        )}
        {/* {!auth?.user && (
            <Tooltip title="reset">
              <Link
                to="/resetLink"
                style={{
                  flexGrow: 1,
                  textAlign: 'center',
                  textDecoration: 'none',
                  color: theme.palette.text.primary
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ flexGrow: 1, width: '100%', textAlign: 'center' }}
                >
                  reset
                </Typography>
              </Link>
            </Tooltip>
          )} */}
        {auth?.user && (
          <Tooltip title="LogOut">
            <IconButton
              size="large"
              aria-label="logout"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                auth.signOut(() => navigate('/'))
              }}
              style={{
                color: theme.palette.mode !== 'dark' ? '#000000' : '#FFFFFF'
              }}
            >
              <Logout />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <Outlet />
      <Box sx={{ bottom: 0, position: 'fixed', right: 0 }}>
        <Fade in={Boolean(alert)} unmountOnExit>
          <Alert
            severity={alert?.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert(null)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alert?.text}
          </Alert>
        </Fade>
      </Box>
    </AppBar>
  )
}

const firebaseConfig = {
  apiKey: 'AIzaSyDiOOI7uVMjtaTRKbrB7fxob-w3jbVUV5A',
  authDomain: 'voxist-f2e51.firebaseapp.com',
  databaseURL: 'https://voxist-f2e51.firebaseio.com',
  projectId: 'voxist-f2e51',
  storageBucket: 'voxist-f2e51.appspot.com',
  messagingSenderId: '1044982363144',
  appId: '1:1044982363144:web:1549e9cc099cd40d5fcdbd',
  measurementId: 'G-9F9E4WZJH7'
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { useAuth, useQuery, useAlert }

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color'
                }
              }
            }
          },
          MuiFilledInput: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: 'inherit',
                  WebkitTextFillColor: 'inherit',
                  caretColor: 'inherit',
                  borderRadius: 'inherit'
                }
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: 'inherit',
                  WebkitTextFillColor: 'inherit',
                  caretColor: 'inherit',
                  borderRadius: 'inherit'
                }
              }
            }
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: 'transparent'
              },
              colorDefault: {
                background: 'transparent'
              }
            }
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                background: 'transparent'
              }
            }
          }
        },
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          ...(prefersDarkMode
            ? {
                // palette values for light mode
                background: {
                  default: '#000000',
                  paper: '#121212'
                }
              }
            : {
                // palette values for dark mode

                background: {
                  default: '#ffffff',
                  paper: '#f8f8f8'
                }
              })
        }
      }),
    [prefersDarkMode]
  )
  return (
    <AlertProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <CheckAuth>
                    <Home />
                  </CheckAuth>
                }
              />
              <Route
                path="/resetLink"
                element={
                  <CheckAuth>
                    <SendReset />
                  </CheckAuth>
                }
              />
              <Route
                path="/reset"
                element={
                  <CheckAuth>
                    <Reset />
                  </CheckAuth>
                }
              />
              <Route
                path="/codeHandler"
                element={
                  <CheckAuth>
                    <CodeHandler />
                  </CheckAuth>
                }
              />

              <Route
                path="/form"
                element={
                  <RequireAuth>
                    <Form />
                  </RequireAuth>
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </AlertProvider>
  )
}

export default App
