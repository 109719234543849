import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const CodeHandler = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const search: URLSearchParams = new URLSearchParams(location.search)
    const mode: string = search.get('mode')
    const code: string = search.get('oobCode')
    switch (mode) {
      case 'resetPassword':

      default:
        navigate(`/reset?mode=${mode}&code=${code}`)
        break
    }
  }, [location])
  return null
}

export default CodeHandler
