import { makeStyles } from '@mui/styles'

import backgroundMain from '../assets/backmain.svg'
import backgroundLog from '../assets/backlog.svg'
import { linearProgressClasses, Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${backgroundMain})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
    minHeight: '100vh',
    paddingTop: 64
  },
  rootlog: {
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${backgroundLog})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',

    minHeight: '100vh'
  },
  titleBox: { marginTop: theme.spacing(6), marginBottom: theme.spacing(6) },
  gridContainer: {
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    color: theme.palette.mode === 'dark' ? '#f8f8f8' : 'rgba(0, 0, 0, 0.54)',
    backgroundColor: theme.palette.mode === 'dark' ? '#00000090' : '#ffffff90'
  },
  paddingBox: {
    padding: theme.spacing(1)
  },
  marginVerticalOnly: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  inputField: {
    // backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#ffffff20',
    borderRadius: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  forgotPassword: {
    textAlign: 'center',

    alignSelf: 'center',

    padding: theme.spacing(2),
    textDecoration: 'none',
    color: '#00CFF3'
  },
  buttonSquare: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#00CFF320' : '#00CFF380'
    },
    borderRadius: theme.spacing(2),
    paddingVertical: theme.spacing(4),
    backgroundColor: '#00CFF3',
    color: '#ffffff',
    textTransform: 'uppercase'
  },
  buttonSelected: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#00CFF320' : '#00CFF380'
    },
    borderRadius: theme.spacing(2),
    paddingVertical: theme.spacing(4),
    backgroundColor: '#00CFF3',
    color: '#ffffff',
    textTransform: 'uppercase'
  },
  buttonUnselected: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#00CFF320' : '#00CFF380'
    },
    borderRadius: theme.spacing(2),
    paddingVertical: theme.spacing(4),
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase'
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#00CFF3'
    }
  },
  transcriptionBox: {
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    backgroundColor:
      theme.palette.mode === 'dark' ? '#00000090' : 'rgba(0, 0, 0, 0.05)'
  },
  appbar: {
    background: 'transparent',
    boxShadow: 'none'
  }
}))

export default useStyles
